import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Header from "../Header";
import React, {useState} from "react";
import Loader from "../../Routes/Loader";


// partners


import intradel from "../../Assets/Images/logos/intradel.jpg";
import jr from "../../Assets/Images/logos/jr.jpg";
import liege from "../../Assets/Images/logos/liege.jpg";
import metz from "../../Assets/Images/logos/metz.jpg";
import natagora from "../../Assets/Images/logos/natagora.jpg";
import noe from "../../Assets/Images/logos/noe.jpg";
import europe from "../../Assets/Images/logos/europe.jpg";


export default function Legals() {
    const [loading, setLoading] = useState('loading');

    setTimeout(()=>{
        setLoading('loaded');
    },100);

    return (
        <div className="App">
            <Loader state={loading} />
            <Header/>
            <div className="content">
                <Navigation/>
                <div className="plantContent">
                    <div className="infos">
                        <strong>À propos</strong>
                        <p>
                            Permacopines vous aide à associer principalement des légumes pour créer des jardins potagers diversifiés et inspirés des principes de la permaculture. Vous allez pouvoir combiner des légumes par paire ce qui aura pour résultat d’augmenter la production de votre potager tout en contribuant à augmenter la biodiversité de celui-ci. Au final, votre jardin sera encore plus beau et plus résistant aux maladies ainsi qu’aux ravageurs.
                            En bonus, les naturalistes de chez Natagora vous expliquerons les interactions bénéfiques entres les insectes et les végétaux au profit de la biodiversité.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Comment ça marche ?</strong>
                        <p>
                            Vous aimeriez connaître le ou les légumes à associer idéalement avec l’un de vos légumes favoris ? C’est simple, il suffit de faire un choix parmi les 30 légumes les plus populaires dans nos régions et de sélectionner ensuite les légumes ou aromates suggérés en association par Permacopines.
                            Le résultat vous indiquera le calendrier idéal et comment les planter ensemble.
                            Vous aurez aussi un bref descriptif du bénéfice de l’association entre les deux variétés de légume ou de l’aromate.
                            Parfois, vous aurez aussi une indication que certains légumes « ne s’aiment pas trop ». Il s’agit seulement d’une indication ! A vous de voir si vous avez envie de tenter l’association. Par exemple, il faut tenir en compte que certains légumes à croissance rapide risquent de faire de l’ombre à des variétés plus petites, ce qui pourrait freiner leur développement et leur goût.
                        </p>
                    </div>

                    <div className="infos">
                        <strong>A qui s’adresse Permacopines ?</strong>
                        <p>
                            Permacopines a été conçue essentiellement pour les jardiniers débutants et amateurs.
                            Celle-ci n’a pas été conçue pour les maraîchers professionnels qui ont d’autres besoins et d’autres contraintes en termes de plan de culture.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Vocabulaire ?</strong>
                        <p>
                            Les mots « caïeux », « semis », « altises » ou « nématodes » vous intriguent ? Pas de panique, nous avons créé une rubrique Lexique pour que vous puissiez décoder la signification des quelques mots techniques que vous pourrez rencontrer en parcourant Permacopines.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Limite d’utilisation et mise en garde.</strong>
                        <p>
                            L’équipe de développement de Permacopines est constituée de maraîchers professionnels qui se sont inspirés de nombreux ouvrages et de leur propre retour d’expérience.
                            Cependant, ils souhaitent mentionner que les associations de légumes et que le résultat de production d’un jardin potager vont dépendre de très nombreux facteurs tels que le climat saisonnier, la qualité des semences (de préférence de qualité biologique et paysanne), la régularité du désherbage éventuel, les fréquences de l’arrosage, les techniques de paillage et de couvre sol, la préparation et l’amendement du sol, la typologie du sol, l’orientation du jardin, son climat et son altitude spécifique, l’utilisation d’une serre ou non, la ventilation ou la protection des légumes à l’extérieur ou à l’intérieur, les rotations de culture, …
                        </p>
                        <p>
                            Permacopines n’est donc pas un manuel de jardinage et nous vous suggérons, si vous en ressentez le besoin, de vous renseigner pour suivre des formations proches de chez vous ou d’acquérir les nombreux ouvrages de références qui existent sur le marché.
                            Le monde du vivant est complexe et les associations de légumes et d’aromates ne sont pas une science exacte. Par conséquent, Permacopines ne pourra en aucun cas être responsable du résultat de votre production au jardin pour les raisons sus-mentionnées.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Fiches pédagogiques gratuites</strong>
                        <p>
                            Vous êtes pressé-e et vous débutez en jardinage ? Voici un lien vers 10 fiches gratuites pour vous aider à planifier dès maintenant votre saison de jardinage de A à Z.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Partenaires</strong>
                        <p>
                            Permacopines est une initiative des partenaires ci-dessous
                        </p>
                        <div className="partnersInfos">
                            <img className="partner" src={noe} alt="Noe-Noah" />
                            <img className="partner" src={natagora} alt="Natagora" />
                            <img className="partner" src={jr} alt="Jardin Ressources" />
                            <img className="partner" src={intradel} alt="intradel" />
                            <img className="partner" src={liege} alt="Liège" />
                            <img className="partner" src={metz} alt="Metz" />
                        </div>
                        <p>
                            Grande Région, qui a donné naissance à un collectif de 26 partenaires transfrontaliers.  De 2017 à 2021, ils ont développé un réseau partagé de reconnexions écologiques innovantes et citoyennes en Grande Région, lié par un double objectif : “valoriser et préserver les corridors de biodiversité, tout en tissant du lien social entre les habitants, avec une attention portée aux enfants et aux personnes les plus vulnérables”. Ensemble, les partenaires ont développé plus de 200 actions : aménagements de jardins, actions de formations tout public ou formations professionnelles, événements culturels, stands d’informations, projets innovants… tel que le logiciel  intitulé " Permacopines".
                        </p>
                        <p>
                            © 2021 Natagora, tous droits réservés – <a href="www.natagora.be" target="_blank">www.natagora.be</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}


