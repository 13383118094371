import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Header from "../Header";
import React from "react";
import mockup from "../../Assets/Images/iphone.png";
import mockupipad from "../../Assets/Images/ipad.png";
import step1 from "../../Assets/Images/step1.png";
import step2 from "../../Assets/Images/step2.png";
import logo from "../../Assets/Images/logo.svg";
import {Link, useLocation} from "react-router-dom";

// partners

import apple from "../../Assets/Images/apple.svg";
import appleW from "../../Assets/Images/apple_white.svg";
import android from "../../Assets/Images/android.png";


import intradel from "../../Assets/Images/logos/intradel.jpg";
import jr from "../../Assets/Images/logos/jr.jpg";
import liege from "../../Assets/Images/logos/liege.jpg";
import metz from "../../Assets/Images/logos/metz.jpg";
import natagora from "../../Assets/Images/logos/natagora.jpg";
import noe from "../../Assets/Images/logos/noe.jpg";
import europe from "../../Assets/Images/logos/europe.jpg";



export default function Landing() {
    const location = useLocation();

    return (
        <div className="App">
            <div className="landing">
                <div className="row presentation">
                    <div className="col colContent">
                        <div className="innerContent">
                            <img className="logo" src={logo} alt="Permacopines" />
                            <h1>
                                Associez vos légumes facilement.
                            </h1>
                            <h2>
                                Permacopines vous aide à associer principalement des légumes pour créer des jardins potagers diversifiés et inspirés des principes de la permaculture.
                            </h2>
                            <Link to="/plants" className="start">Commencer</Link>

                        </div>

                    </div>
                    <div className="col">
                        <nav className="nav">
                            <a href="#about">Comment ça marche?</a>
                            <a href="#partners">Partenariat</a>
                            <Link to="/plants" className="start">Commencer</Link>
                        </nav>
                        <div className="mockupCanvas">
                            <img className="mockup iphone" src={mockup} alt="Permacopines" />
                            <img className="mockup ipad" src={mockupipad} alt="Permacopines" />
                        </div>
                        <div className="links">
                            <span>Permacopines est également disponible sur</span>
                            <div className="buttons">
                                <a href="#" target="_blank">
                                    <img className="button apple" src={apple} alt="AppStore" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.projectperma" target="_blank">
                                    <img className="button android" src={android} alt="Google Play" />
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
                <div id="about">
                        <div className="title">
                            Comment ça marche ?
                        </div>
                    <div className="row">
                        <div className="col">
                            <img className="stepImage" src={step1} alt="Permacopines" />
                        </div>
                        <div className="col">
                            <span className="step">1.</span>
                            <h3>
                                Vous aimeriez connaître le ou les légumes à associer idéalement avec l’un de vos légumes favoris ?
                            </h3>
                            <p>
                                 C’est simple, il suffit de faire un choix parmi les <strong>30 légumes</strong> les plus populaires dans nos régions et de sélectionner ensuite les légumes ou aromates suggérés en association par Permacopines.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className="step">2.</span>
                            <h3>
                                Le résultat vous indiquera le calendrier idéal et comment les planter ensemble.
                                Vous aurez aussi un bref descriptif du bénéfice de l’association entre les deux variétés de légume ou de l’aromate.
                            </h3>
                            <p>
                                Parfois, vous aurez aussi une indication que certains légumes « ne s’aiment pas trop ». Il s’agit seulement d’une <strong>indication</strong> ! A vous de voir si vous avez envie de tenter l’association. Par exemple, il faut tenir en compte que certains légumes à croissance rapide risquent de faire de l’ombre à des variétés plus petites, ce qui pourrait <strong>freiner leur développement et leur goût</strong>.                            </p>
                        </div>
                        <div className="col">
                            <img className="stepImage" src={step2} alt="Permacopines" />
                        </div>
                    </div>
                </div>
                <div id="bannerLinks">
                    <div className="row">
                        <div className="col">
                            <img className="mockup iphone" src={mockup} alt="Permacopines" />
                        </div>
                        <div className="col colText">
                            <strong>Téléchargez l'application</strong>
                            <div className="links">
                                <a href="#" target="_blank">
                                    <img className="button" src={appleW} alt="AppStore" />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.projectperma" target="_blank">
                                    <img className="button android" src={android} alt="Google Play" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="partners">
                    <div className="title">
                        En partenariat avec
                    </div>
                    <div className="row">
                        <img className="partner" src={europe} alt="europe" />
                    </div>
                    <div className="row">
                        <img className="partner" src={noe} alt="Noe-Noah" />
                        <img className="partner" src={natagora} alt="Natagora" />
                        <img className="partner" src={jr} alt="Jardin Ressources" />
                        <img className="partner" src={intradel} alt="intradel" />
                        <img className="partner" src={liege} alt="Liège" />
                        <img className="partner" src={metz} alt="Metz" />
                    </div>
                </div>
                <footer>
                    <div className="row">
                        <div className="col">
                            © 2021 Natagora, tous droits réservés – <a href="www.natagora.be" target="_blank">www.natagora.be</a>
                        </div>
                        <div className="col">
                            <nav className="nav">
                                <Link to="/lexique" className="">Lexique</Link>
                                <Link to="/a-propos" className="">À propos</Link>
                                <Link to="/plants" className="start">Les légumes</Link>
                            </nav>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

