import React from "react";
import {Link} from "react-router-dom";
import logo from "../Assets/Images/logo.svg";

export default function Navigation() {
    return (
        <header className="headerBackground">
            <Link to="/plants" >
                <img className="logo" src={logo} alt="Permacopines" />
            </Link>
        </header>
    );
}
